import React from 'react'
import styles from "./Form.module.css"
import Card from "@material-ui/core/Card";
import HiddenInput from "./HiddenInput";
import InputWithSelect from "./InputWithSelect";
import Multiline from "./Multiline";
import Text from "./Text";
import { DateInput, onChangeDate } from "./DateInput";
import {
    hrPersonsFieldsList
   ,getHrPersonsDB
   ,getHrOnePersonDB
   ,setHrPersonsFields
   ,captions
} from '../../redux/actions/hrPersons';
import {
    GRAY
   ,HIDE
} from "../../redux/constants";


// особый случай, subpoena_receipt_date "Дата получения повестки" - это select, а не date,
// добавим части _dd, _mm, _yyyy
function subpoenaDateParts( name, value ) {
    const [ dd, mm, yyyy ] = value ? value.split(".") : [ "", "", "" ];
    return <>
        <HiddenInput
            name = { name + "_dd" }
            value = { dd }
        />
        <HiddenInput
            name = { name + "_mm" }
            value = { mm }
        />
        <HiddenInput
            name = { name + "_yyyy" }
            value = { yyyy }
        />
    </>;
}


const HrPersons = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;

    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    // protect для внешней формы
    const hidden = props.isExternal && props.protect === HIDE;
    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const protect = props.protect;
    const isExternal = props.isExternal;
    const dispatch = props.dispatch;

    const suffix = props.suffix === undefined || props.suffix === null ? '' : props.suffix;
    const listSuffix = "_list";

    const specialFields = {};
    
    // Выпадающий список с полем ввода для Логина
    let tag = 'hr_login' + suffix;
    let list = props.selectList;
    specialFields.hr_login =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_login }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { value => getHrPersonsDB( dispatch, value ) } // чтение справочника из базы
            onSelectChange = {
                value => {
                 
                    setHrPersonsFields(
                        dispatch
                       ,{
                            [ 'hr_login' + suffix ]: value
                        }
                       ,suffix
                    );
                    
                    if( !value ) return;
                    getHrOnePersonDB( dispatch, value, props.limitToBusinessGroups, suffix ); // чтение всех данных по одному лицу
                }
            } // зависимые поля заполняются значениями из справочника
            // readonly = { readonly } // сюда не передаём, логин можно выбирать
            protect = { protect }
            isExternal = { isExternal }
        />;

    // Выпадающий список с полем ввода для Бизнес-группы
    tag = 'hr_business_group_name' + suffix;
    list = props.state[ 'businessGroupList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_business_group_name =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_business_group_name }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { props.onInputChange }
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        [ 'hr_login'  + suffix ]: props.state[ 'hr_login'  + suffix ]
                       ,[ 'hr_business_group_name' + suffix ]: value
                    }
                   ,suffix
                )
            } // зависимые поля заполняются значениями из справочника
            // readonly = { readonly } // сюда не передаём, можно выбирать
            protect = { protect }
            isExternal = { isExternal }
        />;
        
    // Выпадающий список с полем ввода для Номера назначения
    tag = 'hr_assignment_number' + suffix;
    list = props.state[ 'assignmentsList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_assignment_number =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_assignment_number }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { props.onInputChange }
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_assignment_number' + suffix ]: value
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;

    tag = 'hr_loan_number' + suffix;
    list = props.state[ 'loanList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_loan_number =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_loan_number }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { props.onInputChange }
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_loan_number' + suffix ]: value
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;

    tag = 'subpoena_receipt_date' + suffix;
    list = props.state[ 'subpoenaList' + suffix ];
    let dateParts = '';
    if( Array.isArray( list ) && list.length > 1 ) {
        dateParts = subpoenaDateParts( tag, props.state[ tag ] );
        specialFields.subpoena_receipt_date =
            <InputWithSelect
                className = { props.className }
                key = { tag }
                name = { tag }
                label = { caption.subpoena_receipt_date }
                value = { props.state[ tag ] }
                validators = { props.validators }
                errorMessages = { props.errorMessages }
                list = { list }
                onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
                onInputChange = { props.onInputChange } // undefined, не используется
                onSelectChange = {
                    value => setHrPersonsFields(
                        dispatch
                       ,{
                            ...props.state
                           ,[ 'subpoena_receipt_date' + suffix ]: value
                        }
                       ,suffix
                    )
                }
                protect = { protect }
                isExternal = { isExternal }
            />;
    }
    
/*
    tag = 'hr_contact_type' + suffix;
    list = props.state[ 'contactTypeList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_contact_type =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_contact_type }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_contact_type' + suffix ]: value
                       ,[ 'hr_contact_full_name' + suffix ]: undefined
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
*/
    tag = 'hr_contact_full_name' + suffix;
    list = props.state[ 'contactFullNameList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_contact_full_name =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_contact_full_name }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_contact_type' + suffix ]: undefined
                       ,[ 'hr_contact_full_name' + suffix ]: value
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
        
    tag = 'hr_account_bic' + suffix;
    list = props.state[ 'hr_account_bic' + listSuffix + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_account_bic =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_account_bic }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_account_bic'  + suffix ]: value
                       ,[ 'hr_account_corr' + suffix ]: undefined
                       ,[ 'hr_account_bank' + suffix ]: undefined
                       ,[ 'hr_account_pers' + suffix ]: undefined
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
/*
    tag = 'hr_account_corr' + suffix;
    list = props.state[ 'hrAccountCorrList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_account_corr =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_account_corr }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_account_bic'  + suffix ]: undefined
                       ,[ 'hr_account_corr' + suffix ]: value
                       ,[ 'hr_account_bank' + suffix ]: undefined
                       ,[ 'hr_account_pers' + suffix ]: undefined
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
        
    tag = 'hr_account_bank' + suffix;
    list = props.state[ 'hrAccountBankList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_account_bank =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_account_bank }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_account_bic'  + suffix ]: undefined
                       ,[ 'hr_account_corr' + suffix ]: undefined
                       ,[ 'hr_account_bank' + suffix ]: value
                       ,[ 'hr_account_pers' + suffix ]: undefined
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
        
    tag = 'hr_account_pers' + suffix;
    list = props.state[ 'hrAccountPersList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_account_pers =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_account_pers }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_account_bic'  + suffix ]: undefined
                       ,[ 'hr_account_corr' + suffix ]: undefined
                       ,[ 'hr_account_bank' + suffix ]: undefined
                       ,[ 'hr_account_pers' + suffix ]: value
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
*/
    tag = 'hr_qualification_name' + suffix;
    list = props.state[ 'hr_qualification_name' + listSuffix + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_qualification_name =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_qualification_name }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_qualification_name' + suffix ]: value
                       ,[ 'hr_qualification_establishment' + suffix ]: undefined
                       ,[ 'hr_qualification_doc' + suffix ]: undefined
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
        
    tag = 'hr_qualification_establishment' + suffix;
    list = props.state[ 'hr_qualification_establishment' + listSuffix + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_qualification_establishment =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_qualification_establishment }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_qualification_name' + suffix ]: undefined
                       ,[ 'hr_qualification_establishment' + suffix ]: value
                       ,[ 'hr_qualification_doc' + suffix ]: undefined
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
        
    tag = 'hr_qualification_doc' + suffix;
    list = props.state[ 'hr_qualification_doc' + listSuffix + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_qualification_doc =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_qualification_doc }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_qualification_name' + suffix ]: undefined
                       ,[ 'hr_qualification_establishment' + suffix ]: undefined
                       ,[ 'hr_qualification_doc' + suffix ]: value
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
/*
    tag = 'hr_military_doc' + suffix;
    list = props.state[ 'hrMilitaryDocList' + suffix ];
    if( Array.isArray( list ) && list.length > 1 )
    specialFields.hr_military_doc =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_military_doc }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,{
                        ...props.state
                       ,[ 'hr_military_doc' + suffix ]: value
                    }
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
*/
        
    const fieldsList = Array.isArray( props.fieldsList ) ? props.fieldsList : [];

    const content = hrPersonsFieldsList.map( i =>
        {
            const id = i.id;
            const tag = id + suffix;
            let item = undefined;
            
            // поля с особым поведением
            item = specialFields[ id ];
            
            if( item !== undefined )
                if( fieldsList.includes( id ) )
                    return item;
                else
                    return <HiddenInput key = { tag } name = { tag } value = { props.state[ tag ] } />;
            
            // остальные поля
            if( fieldsList.includes( id ) )
                return id.includes( "date" ) && ( id !== 'subpoena_appearence_date_time' ) ? // subpoena_appearence_date_time содержит дату и время, "13.10.2022 19:00:00"
                <DateInput
                    key = { tag }
                    className = { props.className }
                    label = { caption[ id ] }
                    name = { tag }
                    value = { props.state[ tag ] }
                    value_dd = { props.state[ tag + "_dd" ] }
                    value_mm = { props.state[ tag + "_mm" ] }
                    value_yyyy = { props.state[ tag + "_yyyy" ] }
                    onChange = { onChangeDate( tag, props.onNameValueChange ) }
                    validators = { props.validators }
                    errorMessages = { props.errorMessages }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                    isDefaultDate = { false } // не устанавливать текущую дату по умолчанию
                />
                :
                id === 'hr_tk_status_name' ?
                <Text
                    key = { tag }
                    className = { props.className }
                    name = { tag }
                    label = { caption[ id ] + ": " + ( props.state[ tag ] ?? '' ) }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                />
                :
                <Multiline
                    key = { tag }
                    className = { props.className }
                    label = { caption[ id ] }
                    name = { tag }
                    value = { props.state[ tag ] }
                    onChange = { props.onChange }
                    validators = { props.validators }
                    errorMessages = { props.errorMessages }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                />;
            else
                // невидимые поля должны попасть в form submit
                return <HiddenInput key = { tag } name = { tag } value = { props.state[ tag ] } />;
        }
    );
    
    if( hidden ) return content;
    
    
    return (
        <Card className={ styles.xxComponentCard }>
        <div className={ styles.xxComponentCardLabel }>{ props.label }</div>
        <div className={ styles.xxComponentCardContent }>
            { content }
            { dateParts }
        </div>
        </Card>
    );
}

export default HrPersons
