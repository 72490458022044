import React from 'react';
import styles from "./Form.module.css"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineRounded from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteSweepRounded from '@material-ui/icons/DeleteSweepRounded';
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';

import { ValidatorForm } from 'react-material-ui-form-validator';
import Movable from '../Movable/Movable';
import listStyles from "./ListMovable.module.css"
import ListItem from "../AdminParts/ListItem"
import Grow from '@material-ui/core/Grow';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

// редактор списка list из DocParts.js

const ListEditor = ( props ) => {

    if( !props.open ) return '';
    const onOk = props.onOk === undefined ? props.onCancel : () => { props.onCancel(); props.onOk( props.idx, props ); }

    const list_en = Array.isArray( props.list_en ) ? props.list_en : [];
    
    const DynForm = props.list.map( (item, key) => ListItem( {
            item
           ,item_en: list_en[ key ]
           ,key
           ,optionsList: props.optionsList
           ,onChange: props.onChangeLi // для компонентов с event-ами
           //,onNameValueChange: ( name, value ) => props.dispatch( setKeyPropValue( FORM_TEMPLATE, key, name, value ) ) // для компонентов без event-ов
           ,onDelete: () => props.onDeleteLi( key )
           ,onBankDetailsList: () => props.onBankDetailsList( props.idx, key, item, props.yaOrgs, props.docTemplate, props.suffix )
           ,bankDetails: props.bankDetails
        } ) );

    return (
        <Dialog
            open={ true }
            TransitionComponent={ Transition }
            onBackdropClick={ props.onCancel }
            onEscapeKeyDown={ props.onCancel }
        >
        
            <ValidatorForm
                id="listEditorForm"
                onSubmit={ onOk }
                className={ styles.xxForm }
            >

                {/*<DialogTitle>Поле</DialogTitle>*/}
                
                <DialogContent>
                
                {
                    props.optionsList === undefined ? '' :
                    <Tooltip title="Добавить все" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 0, right: 63 }}
                        onClick={ props.onAddLiAll }
                    >
                        <AddCircleRounded fontSize="large" className={ styles.xxAdd } />
                    </IconButton>
                    </Tooltip>
                }
                
                    <Tooltip title="Удалить все" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 0, right: 3 }}
                        onClick={ props.onDeleteLiAll }
                    >
                        <DeleteSweepRounded fontSize="large" className={ styles.xxDelete } />
                    </IconButton>
                    </Tooltip>
                        
                    {/*<Tooltip title="Закрыть" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 0, right: 5 }}
                        onClick={ props.onCancel }
                    >
                        <ClearRounded fontSize="large" className={ styles.xxDelete } />
                    </IconButton>
                    </Tooltip>*/}
                
                    <DialogContentText> Список </DialogContentText>
                    
                    <Movable
                        items={ DynForm }
                        template={ props.list }
                        styles={ listStyles }
                        onOrderChange={ (...args) => props.onChangeOrder( props, ...args ) }
                    />                    
                    
                </DialogContent>
                <DialogActions>
                
                
                    <IconButton
                        style={{ position: "absolute", left: "50%", transform: "translate(-50%, -50%)" }}
                        color="primary"
                        onClick={ props.onAddLi }
                    >
                        <Tooltip title="Добавить значение" arrow placement="top" >
                            <AddCircleOutlineRounded
                                className={ styles.xxAdd }
                                fontSize="large"
                            />
                        </Tooltip>
                    </IconButton>
                
                
                    <Button
                        onClick={ onOk }
                        color="secondary"
                        variant="outlined"
                        size="small"
                        autoFocus
                    > Ок </Button>
                    
                    { props.onOk === undefined ? '' :
                        <Button onClick={ props.onCancel } color="secondary" variant="outlined" size="small" > Отмена </Button>
                    }
                    
                </DialogActions>
            
            </ValidatorForm>
            
        </Dialog>
    );
};

export default ListEditor;
